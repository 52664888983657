import MessagesPage from './pages/messagePage/MessagesPage';
import URLInputBlock from './pages/messagePage/components/URLInputBlock';
import FramesPage from './pages/framesPage/FramesPage';

export default class App {
  constructor() {
    this.URLInput = new URLInputBlock();

    this.pagesContainer = document.getElementById('pagesContainer')
      this.pages = {
        messages: new MessagesPage(this.pagesContainer, this.URLInput),
        frames: new FramesPage(this.pagesContainer, this.URLInput)
      };
    this.initNavigationButtons();
    this.setActivePage(localStorage.getItem('activePage') || 'messages')
  }



  initNavigationButtons() {
      this.buttons = [...document.querySelectorAll('.goToPageButton')]
    this.buttons.forEach(button => button.addEventListener('click', this.onNavigationClick.bind(this)))
  }

  onNavigationClick(e) {
    this.setActivePage(e.target.dataset.page)
  }

  setActivePage(pageName) {
    if (this.pages[pageName] === this.activePage) return;
    this.activePage = this.pages[pageName]
    this.activePage.init();
    this.buttons.forEach(button => button.classList.remove('active'));
    this.buttons.find(button => button.dataset.page === pageName).classList.add('active');
    localStorage.setItem('activePage', pageName);
  }
}


