
export default class FramesPage {
  constructor(container, URLInput) {
    this.container = container;
    this.URLInput = URLInput;
    this.onCloseClick = this.onCloseClick.bind(this);

  }
  _createMarkup() {
    const markup = `
    <div id="framePage">
      <div class="framePage_frameControlPanel">
        <input id="frameControl_input" placeholder="Count"/>
        <button id="framePage_createFramesButton">CREATE</button>
        <input id="frameControl_addInput" placeholder="Count"/>
        <button id="framePage_addFramesButton">ADD</button>
      </div>
      <div id="framePage_framesContainer">
      </div>
    </div>`;
    this.container.innerHTML = markup;
  }

  init() {
    this._createMarkup();
    this.view = document.getElementById('framePage')
    this.countInput = this.view.querySelector('#frameControl_input');
    this.addInput = this.view.querySelector('#frameControl_addInput');
    this.addInput.value = 1;
    this.createButton = this.view.querySelector('#framePage_createFramesButton');
    this.addButton = this.view.querySelector('#framePage_addFramesButton');
    this.framesContainer = this.view.querySelector('#framePage_framesContainer');
    const framesCount = localStorage.getItem('framesCount') || 15;
    this.frames = [];

    const framesURLsString = localStorage.getItem('framesURLs');
    const framesURLs = framesURLsString &&  JSON.parse(framesURLsString);

    this._createFrames(framesCount, framesURLs)

    if (!framesURLs) {
      this.onChangeURL(this.URLInput.value)

    }
    this.addListeners()
  }

  addListeners() {
    this.URLInput.onChange = this.onChangeURL.bind(this);
    this.countInput.addEventListener('keyup', this.onKeyUpInput.bind(this))
    this.createButton.addEventListener('click', () => this._createFrames())
    this.addButton.addEventListener('click', () => this._addFrames())
  }

  onChangeURL(url) {
    console.log(`url changed: ${url}`)
    this.frames.forEach(frame =>frame.myIFrame.src = url);
  }

  onKeyUpInput(event) {
    if (event.key === 'Enter' ) {
      this._createFrames(event.target.value);
    }
  }



  _createFrames(framesCount, framesURLs) {
    if (!framesCount) framesCount = this.countInput.value || 0;
    framesCount = +framesCount;
    this.countInput.value = framesCount;
    localStorage.setItem('framesCount', framesCount);

    while ( this.frames.length > framesCount ) {
      const frame = this.frames[0];
      this._closeFrame(frame)
    }
    const frameLength = this.frames.length;
    for (let i = frameLength; i < framesCount; i++) {
      this._createFrame(framesURLs && framesURLs[i] || this.URLInput.value, i)
    }
  }

  _createFrame(url, index) {
    const frameWrapper = document.createElement('div');
    const closeButton = document.createElement('button');
    closeButton.classList.add('close')
    const frame  = document.createElement('iframe')
    frameWrapper.appendChild(closeButton);
    frameWrapper.myIFrame = frame;
    frameWrapper.appendChild(frame);
    this.framesContainer.appendChild(frameWrapper)
    closeButton.textContent = "✖";
    frame.src = url;
    this.frames.push(frameWrapper);
    const framesURLsString = localStorage.getItem('framesURLs');
    const framesURLs = framesURLsString &&  JSON.parse(framesURLsString);
    if (framesURLs && !framesURLs[index]) {
      framesURLs.push(url);
      localStorage.setItem('framesURLs', JSON.stringify(framesURLs));
    } else if (!framesURLs)  {
      localStorage.setItem('framesURLs', JSON.stringify([url]));
    }
    closeButton.addEventListener('click', this.onCloseClick)
  }

  onCloseClick(e) {
    const frameWrapper = e.target.parentElement;
    this._closeFrame(frameWrapper);
    e.target.removeEventListener('click', this._closeFrame)
  }

  _closeFrame(frameWrapper) {
    this.frames = this.frames.filter((frame, index) => {
      if (frame !== frameWrapper) {
        return true
      } else {
        const framesURLsString = localStorage.getItem('framesURLs');
        let framesURLs = framesURLsString &&  JSON.parse(framesURLsString);
        if (framesURLs) {
          framesURLs.splice(index, 1);
          localStorage.setItem('framesURLs', JSON.stringify(framesURLs));
        }
        return false;
      }
    })
    frameWrapper.remove();
    const framesCount = this.frames.length;
    this.countInput.value = framesCount;
    localStorage.setItem('framesCount', `${framesCount}`)
  }

  _addFrames() {
    const framesCount =  this.frames.length + (+this.addInput.value || 0);
    this._createFrames(framesCount)
  }
}



