 export default class MessageFeet {
  constructor() {
    this.MessageFeetElement = document.getElementById('message_feet')
    this.clearButton = document.getElementById('clear_button');
    this.stack = [];
    this._messageCount = 0;
    this.clearButton.addEventListener('click', this._clear.bind(this));
  }

   addMessage(messageData) {
    const date = new Date();
      let messageJSON = JSON.stringify(messageData)
        .replace(/{/g, `{\n  `)
        .replace(/,/g, ',\n  ')
        .replace(/}/g, '\n}');
      messageJSON = `${++this._messageCount}. ${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}\n` + messageJSON;
      this._showMessage(messageJSON);
   }
   _clear() {
     this.stack.forEach(massageElement => massageElement.remove())
     this.stack = [];
   }
   _showMessage(massage) {
    const massageElement = document.createElement('p')
     massageElement.textContent = massage;
     this.stack.push(massageElement);
     this.MessageFeetElement.prepend(massageElement);
     console.log(massage);
   }
}

