// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_AT_RULE_IMPORT_0___ = require("-!../node_modules/css-loader/dist/cjs.js!./js/pages/messagePage/messagePage.css");
var ___CSS_LOADER_AT_RULE_IMPORT_1___ = require("-!../node_modules/css-loader/dist/cjs.js!./js/pages/framesPage/framePage.css");
exports = ___CSS_LOADER_API_IMPORT___(false);
exports.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
exports.i(___CSS_LOADER_AT_RULE_IMPORT_1___);
// Module
exports.push([module.id, "body {\n  overflow: auto;\n}\n\n#header #pagesButtonBlock .goToPageButton {\n  background-color: rgb(65, 198, 10);\n  color: #fff;\n  border: none;\n  outline: none;\n  border-radius: 4px;\n  padding: 5px 10px;\n  cursor: pointer;\n}\n\n#header #pagesButtonBlock .goToPageButton:hover {\n  background-color: rgb(65, 198, 10);\n}\n\n#header #pagesButtonBlock .goToPageButton:active {\n  background-color: rgb(65, 198, 10);\n}\n\n#header #pagesButtonBlock .goToPageButton.active {\n  background-color: rgb(10, 198, 101);\n  cursor: auto;\n}\n\ninput {\n  border: rgb(184, 181, 255) 2px solid;\n  border-radius: 4px;\n  padding: 5px 10px;\n  margin: 5px;\n}\n\nbutton {\n\n}\n\n#url_input {\n  width: 93%;\n}\nbutton{\n  background-color: rgb(65, 198, 10);\n  color: #fff;\n  border: none;\n  outline: none;\n  border-radius: 4px;\n  padding: 5px 10px;\n  cursor: pointer;\n}\n\nbutton:hover{\n  background-color: rgb(113, 205, 169);\n}\n\nbutton:active{\n  background-color: rgb(16, 130, 76);\n}\n\n\n", ""]);
// Exports
module.exports = exports;
