import MessageFeet from './components/MessageFeet.js';
import URLInputBlock from './components/URLInputBlock.js';

export default class MessagesPage {
  constructor(container, URLInput) {
    this.container = container;
    this.URLInput = URLInput;
  }
  _createMarkup() {
    const markup = `
    <div id="messagePage">
      <iframe id="frame" allow="fullscreen" src=""></iframe>
      <div class="rightSide">
        <button id="clear_button">CLEAR</button>
        <div id="message_feet"></div>
      </div>
    </div>`;
    this.container.innerHTML = markup;
  }

  init() {
    this._createMarkup();

    this.frame = document.getElementById('frame');
    this.MessageFeet = new MessageFeet();
    this.onChangeURL(this.URLInput.value)

    this.addListeners()
  }

  onChangeURL(url) {
    console.log(`url changed: ${url}`)
    this.frame.src = url;
  }

  onMessage(event) {
    this.MessageFeet.addMessage(event.data);
  }

  addListeners() {
    this.URLInput.onChange = this.onChangeURL.bind(this);
    window.addEventListener('message', this.onMessage.bind(this))
  }
}



