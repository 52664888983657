
export default class URLInputBlock {
  constructor() {
    this.onChange = (url) => {}

    this.input = document.getElementById('url_input');
    this.button = document.getElementById('url_button');

    const url = localStorage.getItem('url');
    if (url) {
      this.value = url;
    }

    this.input.addEventListener('keyup', this.onKeyUpInput.bind(this))
    this.button.addEventListener('click', this.onButtonClicked.bind(this))
  }

  set value(value) {
    this.input.value = value;
    this._onChange(value);
  }

  get value () {
    return this.input.value;
  }

  onKeyUpInput(event) {
    if (event.key === 'Enter' ) {
      this._onChange(event.target.value);
    }
  }

  onButtonClicked() {
    this._onChange(this.input.value);
  }

  _onChange(url) {
    localStorage.setItem('url', url);
    this.onChange(url);
  }

}
